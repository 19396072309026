import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { loadStripe } from "@stripe/stripe-js";

import logo from './logo.png'

import playIcon from './img/play_2.svg'

import stopIcon from './img/stop.svg'
import arrowRightIcon from './img/arrow-right.svg'
import arrowLeftIcon from './img/arrow-left.svg'
import menuIcon from './img/menu-icon.svg'
import './App.css';

import books from './content/books.json'

const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [currentChunkPlaying, setCurrentChunkPlaying] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)
  const isPurchased = false

  // If not purchased only keep the first book active as a demo
  const activeBooks = isPurchased ? books : books.slice(0, 2)
  books[0] = {
    ...books[0],
    contentSummary: {
      ...books[0].contentSummary,
      chunks: books.slice(1, books.length).map((book, index) => {
        return {
          "id": index - 100,
          "summaryId": 0,
          "order": 7,
          "title": `Livro ${index + 1}: ${book.title}`,
          "text": book.finalDetails,
          "createdAt": "2023-07-04T19:03:14.497Z",
          "updatedAt": "2023-07-04T20:12:33.455Z"
        }
      })
    }
  }

  const [currentBook, setCurrentBook] = useState(books[0])

  const preloadImage = useCallback((im_url) => {
    let img = new Image();
    img.src = im_url;
  }, [])

  const findBookByChunkId = (chunkId) => {
    return activeBooks
      .find(book => book.contentSummary.chunks.map(chunk => String(chunk.id)).includes(String(chunkId)))
  }

  const isCurrentBookPlaying = isPlaying && currentBook.contentSummary.chunks.map(({ id }) => id).includes(currentChunkPlaying.id)
  const isInTheSameBookThatIsInThePlayer = currentBook.id === findBookByChunkId(currentChunkPlaying.id)?.id

  const play = useCallback((chunkId, _book) => {
    const firstChunk = (_book || currentBook)
      .contentSummary
      .chunks
      .sort((a, b) => a.order - b.order)[0]

    const currentChunkId = chunkId || firstChunk.id

    const book = findBookByChunkId(currentChunkId)

    const chunkToPlay = book.contentSummary.chunks.find(({ id }) => id === currentChunkId)

    setCurrentChunkPlaying(chunkToPlay)
    setIsPlaying(true)
  }, [currentBook])

  const pause = () => {
    setIsPlaying(false)
  }

  const playNextChunk = () => {
    const currendBookPlaying = findBookByChunkId(currentChunkPlaying.id)
    const sortedChunks = currendBookPlaying.contentSummary.chunks.sort((a, b) => a.order - b.order)
    const currentChunkIndex = sortedChunks.findIndex(({ id }) => id === currentChunkPlaying.id)
    const nextChunk = sortedChunks[currentChunkIndex + 1]

    if (nextChunk) {
      play(nextChunk.id, currendBookPlaying)
    }
  }

  const playPreviousChunk = () => {
    const currendBookPlaying = findBookByChunkId(currentChunkPlaying.id)
    const sortedChunks = currendBookPlaying.contentSummary.chunks.sort((a, b) => a.order - b.order)
    const currentChunkIndex = sortedChunks.findIndex(({ id }) => id === currentChunkPlaying.id)
    const previousChunk = sortedChunks[currentChunkIndex - 1]

    if (previousChunk) {
      play(previousChunk.id, currendBookPlaying)
    }
  }

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      pause()
    }
    else {
      play(currentChunkPlaying?.id)
    }
  }, [isPlaying, currentChunkPlaying?.id, play, pause])


  const isChunkPlaying = (chunkId) => {
    return isPlaying && currentChunkPlaying.id === chunkId
  }

  const handlePurchaseButtonClick = async () => {
    // Call your backend to create the Checkout Session
    const response = await fetch(process.env.REACT_APP_API_URL + "/payments/stripe/session", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        priceId: 'price_1NKUEaJeN2TlCOyRXJ9waOlk',
        originUrl: window.location.origin,
      }),
    });
    const session = await response.json();

    // Redirect to Checkout
    await stripe.redirectToCheckout({ sessionId: session.id });
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        event.preventDefault()
        togglePlay()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [togglePlay])

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
    }
    else {
      audioRef.current.pause()
    }
  }, [isPlaying])

  useEffect(() => {
    if (isPlaying && currentChunkPlaying) {
      audioRef.current.play()
    }
  }, [currentChunkPlaying, isPlaying])

  useEffect(() => {
    activeBooks.forEach((book) => {
      preloadImage(book.imageUrl)
    })
  }, [preloadImage])

  useEffect(() => {
    // scroll page to the top
    window.scrollTo(0, 0)
  }, [currentBook, currentChunkPlaying])


  const renderPurchaseButton = () => {
    if (isPurchased) {
      return null
    }

    return (
      <button
        className="purchase-button"
        onClick={handlePurchaseButtonClick}
      >
        Comprar por R$ 47,00
      </button>
    )
  }

  return (
    <div className="App">
      <header className="mobile-menu">
        <div className="logo-container">
          <img src={logo} alt="logo" />
          <h1>DeltaClub</h1>
        </div>
        <button
          className="menu-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <img style={{ width: 30, height: 30 }} src={menuIcon} alt="menu" />
        </button>
      </header>

      <nav
        className={['drawer', isMenuOpen ? 'drawer--open' : 'drawer--hidden'].join(' ')}
      >
        <div className="logo-container logo-in-navbar">
          <img src={logo} alt="logo" />
          <h1>DeltaClub</h1>
        </div>

        {activeBooks.map((book, index) => (
          <div
            key={index}
            className={[
              'drawer-link',
              currentBook.id === book.id ? 'drawer-link--active' : ''
            ].join(' ')}
            onClick={() => {
              setCurrentBook(book)
              setIsMenuOpen(false)
            }}
          >{book.title} por {book.author}</div>
        ))}
      </nav>

      <main className="main-content">
        <section className="hero">
          <img src={currentBook.imageUrl} alt={currentBook.title} />

          <div className="hero-info">
            {currentBook.contentSummary?.chunks[0]?.audio && (
              <div className="hero-buttons">
                <button className="hero-button" onClick={isInTheSameBookThatIsInThePlayer ? togglePlay : () => play()}>
                  <img style={{ width: 40, height: 40 }} src={isCurrentBookPlaying ? stopIcon : playIcon} alt="play control" />
                </button>
              </div>
            )}

            <div className="hero-text">
              <h2>{currentBook.title}</h2>
              <h3>por {currentBook.author}</h3>
            </div>
          </div>

        </section>

        <section className="text">
          {renderPurchaseButton()}

          {currentBook.contentSummary.chunks.sort((a, b) => a.order - b.order).map((chunk, index) => (
            <section key={index} className={[currentChunkPlaying.id === chunk.id ? 'section--active': isInTheSameBookThatIsInThePlayer ? 'section--hide' : ''].join(' ')}>
              {(index === 0 && !isChunkPlaying(chunk.id)) && (
                <div className="deltaclub-description">
                  <h2>{currentBook.title}</h2>

                  <ReactMarkdown className="text-body">{currentBook.finalDetails}</ReactMarkdown>
                </div>
              )}
              <h2 className={[isChunkPlaying(chunk.id) ? 'active': ''].join(' ')}>{chunk.title}</h2>
              <ReactMarkdown className="text-body">{chunk.text}</ReactMarkdown>
            </section>
          ))}

          {renderPurchaseButton()}
        </section>
      </main>

      <section className="sidebar">
        <div className="sidebar-content">
          <div className="sidebar-text">
            <h2>Suporte:</h2>
            <h3>contato@deltaclub.app</h3>
          </div>
        </div>

        <div className="sidebar-content">
          <div className="sidebar-text">
            {/* In portuguese */}
            <h3>Ao utilizar os serviços e produtos da DeltaClub, você reconhece e concorda em cumprir nossos <a href='https://www.deltaclub.app/terms-and-conditions/' target='_blank' rel="noreferrer">termos e condições</a>, <a href='https://www.deltaclub.app/data-policy/' target='_blank' rel="noreferrer">política de dados</a> e <a href='https://www.deltaclub.app/cookies-policy/' target='_blank' rel="noreferrer">política de cookies</a>.</h3>
          </div>
        </div>
      </section>

      {currentChunkPlaying && (
        <div className="player">
          <h2>{findBookByChunkId(currentChunkPlaying.id).title}</h2>
          <h3>{currentChunkPlaying.title}</h3>
          <div style={{ display: 'inline-flex', alignItems: 'center', margin: '10px 0' }}>
            <button className="hero-button" onClick={playPreviousChunk}>
              <img style={{ width: 40, height: 40 }} src={arrowLeftIcon} alt="play previous book summary text" />
            </button>

            <button className="hero-button" onClick={togglePlay}>
              <img style={{ width: 65, height: 65 }} src={isPlaying ? stopIcon : playIcon} alt="play control" />
            </button>

            <button className="hero-button" onClick={playNextChunk}>
              <img style={{ width: 40, height: 40 }} src={arrowRightIcon} alt="play next book sumary text" />
            </button>
          </div>
        </div>
      )}

      <audio
        style={{ display: 'none' }}
        // controls
        ref={audioRef}
        src={currentChunkPlaying?.audio}
        onEnded={() => {
          const currendBookPlaying = findBookByChunkId(currentChunkPlaying.id)
          const sortedChunks = currendBookPlaying.contentSummary.chunks.sort((a, b) => a.order - b.order)
          const currentChunkIndex = sortedChunks.findIndex(({ id }) => id === currentChunkPlaying.id)
          const nextChunk = sortedChunks[currentChunkIndex + 1]

          if (nextChunk) {
            play(nextChunk.id)
          }
          else {
            pause()
          }
        }}
        onPlay={() => {
          setIsPlaying(true)
        }}
        onPause={() => {
          setIsPlaying(false)
        }}
      />
    </div>
  );
}

export default App;
